<template>
  <div>
    <!-- <b-modal
    id="bv-add-address"
    size="lg"
    :hide-footer="true"
    :hide-header="true"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    centered
    header-class="my-class"
    body-class="p-0"
  > -->
    <b-card class="card-custom-class">
      <div class="closemodel">
        <button
          @click="
            $bvModal.hide('bv-add-address');
            reset();
          "
          type="button"
        >
          <span>
            <img src="img/address/close.png" alt />
          </span>
        </button>
      </div>
      <b-icon
        class="mr-1"
        icon="geo-alt-fill"
        scale="2"
        variant="success"
      ></b-icon>
      <span class="change-location-text">Add Address</span>
    </b-card>
    <b-card>
      <v-form>
        <GmapAutocomplete
          id="LocationAddress12"
          @place_changed="placechange1"
          ref="address12"
          class="form-control tx-13"
          placeholder="Enter Your Location"
          :options="{
            fields: ['geometry', 'formatted_address', 'address_components'],
          }"
        ></GmapAutocomplete>
        <v-container>
          <ValidationObserver v-slot="{ invalid }">
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="Full name"
                >
                  <v-text-field
                    v-model="fullName"
                    label="Full name"
                  ></v-text-field>
                  <span class="validate-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="House Number"
                >
                  <v-text-field
                    v-model="houseNumber"
                    label="House Number"
                  ></v-text-field>
                  <span class="validate-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="Building, Company, Apartment"
                >
                  <v-text-field
                    v-model="building"
                    label="Building, Company, Apartment"
                  ></v-text-field>
                  <span class="validate-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="Area, Colony, Street, Sector, Village"
                >
                  <v-text-field
                    v-model="area"
                    label="Area, Colony, Street, Sector, Village"
                  ></v-text-field>
                  <span class="validate-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <ValidationProvider
                  rules="required|numeric"
                  v-slot="{ errors }"
                  name="zip"
                >
                  <v-text-field v-model="zip" label="Zip"></v-text-field>
                  <span class="validate-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <ValidationProvider
                  rules="required|max:20|min:2"
                  v-slot="{ errors }"
                  name="City"
                >
                  <v-text-field v-model="city" label="City"></v-text-field>
                  <span class="validate-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <ValidationProvider
                  rules="required|max:20|min:2"
                  v-slot="{ errors }"
                  name="State"
                >
                  <v-text-field v-model="state" label="State"></v-text-field>
                  <span class="validate-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <ValidationProvider
                  rules="required|max:20|min:2"
                  v-slot="{ errors }"
                  name="Country"
                >
                  <v-text-field
                    v-model="country"
                    label="Country"
                  ></v-text-field>
                  <span class="validate-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <ValidationProvider
                  rules="required|digits:10"
                  v-slot="{ errors }"
                  name="phone Number"
                >
                  <v-text-field
                    v-model="phoneNumber"
                    label="Phone Number"
                  ></v-text-field>
                  <span class="validate-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <ValidationProvider
                  rules="required|email"
                  v-slot="{ errors }"
                  name="Email"
                >
                  <v-text-field
                    v-model="email"
                    label="Email"
                    placeholder="Email"
                  ></v-text-field>
                  <span class="validate-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <b-form-select
                  v-model="addressType"
                  placeholder="Select an Address Type "
                  name="Address Type"
                  id="Address Type"
                  class="form-control"
                  size="1"
                >
                  <option disabled value="Select an Address Type">
                    Select an Address Type
                  </option>
                  <option value="Home">Home (7 am – 9 pm delivery)</option>
                  <option value="Office">
                    Office/Commercial (10 AM - 6 PM delivery)
                  </option>
                  <option value="Other">Other</option>
                </b-form-select>
              </v-col>
              <v-col
                style="margin-top: 4px; font-size: 19px"
                cols="12"
                sm="6"
                md="6"
              >
                <b-row>
                  <b-col md="5">
                    <b-card-text class="price-details"
                      >Default Address</b-card-text
                    >
                  </b-col>
                  <b-col md="7">
                    <b-form-radio-group
                      v-model="defaultAddressKey"
                      id="radio-group-2"
                      name="radio-sub-component"
                    >
                      <b-form-radio size="lg" :value="true">Yes</b-form-radio>
                      <b-form-radio size="lg" :value="false">No</b-form-radio>
                    </b-form-radio-group>
                  </b-col>
                  <!-- <b-col md="3"> </b-col> -->
                </b-row>
              </v-col>

              <b-col class="text-center">
                <template v-if="!isLoading">
                  <button
                    @click="
                      $bvModal.hide('bv-add-address');
                      reset();
                    "
                    type="button"
                    class="btn btn-outline-primary btn-lg mr-3"
                  >
                    <span class="button-text"></span> Cancel
                  </button>
                  <button
                    @click="onSubmitAddress"
                    type="button"
                    :disabled="invalid"
                    class="btn btn-primary btn-lg"
                  >
                    <span class="button-text"></span> Save
                  </button>
                </template>
                <template v-if="isLoading">
                  <b-spinner
                    v-for="i in 3"
                    :key="i"
                    class="mr-1"
                    small
                    label="Small Spinner"
                    type="grow"
                  ></b-spinner>
                </template>
              </b-col>
            </v-row>
          </ValidationObserver>
        </v-container>
      </v-form>
    </b-card>
    <!-- </b-modal> -->
  </div>
</template>
<script>
import locationExtract from "../../../shared/mixins/locationExtract";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [locationExtract],
  data() {
    return {
      fullName: "",
      houseNumber: "",
      building: "",
      area: "",
      zip: "",
      city: "",
      state: "",
      country: "",
      phoneNumber: "",
      email: "",
      addressType: "Select an Address Type",
      defaultAddressKey: false,
      isLoading: false,
    };
  },
  methods: {
    reset() {
      Object.assign(this.$data, this.$options.data());
    },
    async placechange1(e) {
      console.log(this.extractLocation(e));
      let extractData = await this.extractLocation(e);
      if (Object.keys(extractData).length != 0) {
        if (extractData.data.Country) {
          this.country = extractData.data.Country;
        }
        if (extractData.data.State) {
          this.state = extractData.data.State;
        }
        if (extractData.data.zip) {
          this.zip = extractData.data.zip;
        }
        if (extractData.data.City) {
          this.city = extractData.data.City;
        }
      }
    },
    async onSubmitAddress() {
      this.isLoading = true;
      let payload = {
        fullName: this.fullName,
        houseNumber: this.houseNumber,
        street2: this.building,
        street1: this.area,
        zip: this.zip,
        city: this.city,
        state: this.state,
        country: this.country,
        phoneNumber: this.phoneNumber,
        email: this.email,
        category: this.addressType,
        isDefault: this.defaultAddressKey,
      };
      await this.$store
        .dispatch("address/adAddress", payload)
        .then((res) => {
          console.log(res);
          this.$bvModal.hide("bv-add-address");
          this.$swal({
            title: "Success!",
            text: "Address added successfully",
            type: "success",
          });
          this.reset();
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.$bvModal.hide("bv-add-address");
          this.$swal({
            title: "Error!",
            text: "Can not add address please try again",
            type: "error",
          });
          this.reset();
        });
      await this.$store.dispatch("checkout/checkoutFinal");
      await this.$store.dispatch("address/fetchDeliveryAddress");
    },
  },
  props: {},
};
</script>
<style scoped>
.card-custom-class {
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-bottom-color: rgba(0, 0, 0, 0.125);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-radius: 0.25rem;
}
/*  */
.list-group-modal {
  margin-top: 7px;
  max-height: 540px;
  margin-left: 10px;
  margin-right: 4px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.change-location-text {
  font-style: normal;
  font-weight: 520;
  font-size: 26px;
  line-height: 24px;
  margin-left: 5px;
  color: #2e2e2e;
}
.coupon-input {
  border: 0;
  border-bottom: 1px solid rgba(33, 33, 33, 0.08);
  outline: 0;
  width: 75%;
}
.text-danger {
  color: #fe7b08 !important;
  font-size: 14px;
  vertical-align: 5px;
}
.add-new-address-text {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #fe7b08;
  cursor: pointer;
  position: absolute;
  right: 75px;
}
.address-name {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #000000;
}
.body-class-modal {
  margin-top: -7px;
}

.address-details-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  color: #2e2e2e;
  margin-left: 37px;
  margin-top: 4px;
}

.my-class {
  background: black;
  color: white;
}
.closemodel {
  position: absolute;
  top: 8px;
  right: 3px;
}

.default-address-class {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #717171;
  margin-left: 10px;
}
</style>
