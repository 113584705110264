var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    staticClass: "card-custom-class"
  }, [_c('div', {
    staticClass: "closemodel"
  }, [_c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.$bvModal.hide('bv-add-address');

        _vm.reset();
      }
    }
  }, [_c('span', [_c('img', {
    attrs: {
      "src": "img/address/close.png",
      "alt": ""
    }
  })])])]), _c('b-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "geo-alt-fill",
      "scale": "2",
      "variant": "success"
    }
  }), _c('span', {
    staticClass: "change-location-text"
  }, [_vm._v("Add Address")])], 1), _c('b-card', [_c('v-form', [_c('GmapAutocomplete', {
    ref: "address12",
    staticClass: "form-control tx-13",
    attrs: {
      "id": "LocationAddress12",
      "placeholder": "Enter Your Location",
      "options": {
        fields: ['geometry', 'formatted_address', 'address_components']
      }
    },
    on: {
      "place_changed": _vm.placechange1
    }
  }), _c('v-container', [_c('ValidationObserver', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6",
            "md": "6"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "name": "Full name"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('v-text-field', {
                attrs: {
                  "label": "Full name"
                },
                model: {
                  value: _vm.fullName,
                  callback: function callback($$v) {
                    _vm.fullName = $$v;
                  },
                  expression: "fullName"
                }
              }), _c('span', {
                staticClass: "validate-error"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6",
            "md": "6"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "name": "House Number"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('v-text-field', {
                attrs: {
                  "label": "House Number"
                },
                model: {
                  value: _vm.houseNumber,
                  callback: function callback($$v) {
                    _vm.houseNumber = $$v;
                  },
                  expression: "houseNumber"
                }
              }), _c('span', {
                staticClass: "validate-error"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6",
            "md": "6"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "name": "Building, Company, Apartment"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('v-text-field', {
                attrs: {
                  "label": "Building, Company, Apartment"
                },
                model: {
                  value: _vm.building,
                  callback: function callback($$v) {
                    _vm.building = $$v;
                  },
                  expression: "building"
                }
              }), _c('span', {
                staticClass: "validate-error"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6",
            "md": "6"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "name": "Area, Colony, Street, Sector, Village"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var errors = _ref5.errors;
              return [_c('v-text-field', {
                attrs: {
                  "label": "Area, Colony, Street, Sector, Village"
                },
                model: {
                  value: _vm.area,
                  callback: function callback($$v) {
                    _vm.area = $$v;
                  },
                  expression: "area"
                }
              }), _c('span', {
                staticClass: "validate-error"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6",
            "md": "6"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required|numeric",
            "name": "zip"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var errors = _ref6.errors;
              return [_c('v-text-field', {
                attrs: {
                  "label": "Zip"
                },
                model: {
                  value: _vm.zip,
                  callback: function callback($$v) {
                    _vm.zip = $$v;
                  },
                  expression: "zip"
                }
              }), _c('span', {
                staticClass: "validate-error"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6",
            "md": "6"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required|max:20|min:2",
            "name": "City"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref7) {
              var errors = _ref7.errors;
              return [_c('v-text-field', {
                attrs: {
                  "label": "City"
                },
                model: {
                  value: _vm.city,
                  callback: function callback($$v) {
                    _vm.city = $$v;
                  },
                  expression: "city"
                }
              }), _c('span', {
                staticClass: "validate-error"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6",
            "md": "6"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required|max:20|min:2",
            "name": "State"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref8) {
              var errors = _ref8.errors;
              return [_c('v-text-field', {
                attrs: {
                  "label": "State"
                },
                model: {
                  value: _vm.state,
                  callback: function callback($$v) {
                    _vm.state = $$v;
                  },
                  expression: "state"
                }
              }), _c('span', {
                staticClass: "validate-error"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6",
            "md": "6"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required|max:20|min:2",
            "name": "Country"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref9) {
              var errors = _ref9.errors;
              return [_c('v-text-field', {
                attrs: {
                  "label": "Country"
                },
                model: {
                  value: _vm.country,
                  callback: function callback($$v) {
                    _vm.country = $$v;
                  },
                  expression: "country"
                }
              }), _c('span', {
                staticClass: "validate-error"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6",
            "md": "6"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required|digits:10",
            "name": "phone Number"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref10) {
              var errors = _ref10.errors;
              return [_c('v-text-field', {
                attrs: {
                  "label": "Phone Number"
                },
                model: {
                  value: _vm.phoneNumber,
                  callback: function callback($$v) {
                    _vm.phoneNumber = $$v;
                  },
                  expression: "phoneNumber"
                }
              }), _c('span', {
                staticClass: "validate-error"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6",
            "md": "6"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required|email",
            "name": "Email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref11) {
              var errors = _ref11.errors;
              return [_c('v-text-field', {
                attrs: {
                  "label": "Email",
                  "placeholder": "Email"
                },
                model: {
                  value: _vm.email,
                  callback: function callback($$v) {
                    _vm.email = $$v;
                  },
                  expression: "email"
                }
              }), _c('span', {
                staticClass: "validate-error"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6",
            "md": "6"
          }
        }, [_c('b-form-select', {
          staticClass: "form-control",
          attrs: {
            "placeholder": "Select an Address Type ",
            "name": "Address Type",
            "id": "Address Type",
            "size": "1"
          },
          model: {
            value: _vm.addressType,
            callback: function callback($$v) {
              _vm.addressType = $$v;
            },
            expression: "addressType"
          }
        }, [_c('option', {
          attrs: {
            "disabled": "",
            "value": "Select an Address Type"
          }
        }, [_vm._v(" Select an Address Type ")]), _c('option', {
          attrs: {
            "value": "Home"
          }
        }, [_vm._v("Home (7 am – 9 pm delivery)")]), _c('option', {
          attrs: {
            "value": "Office"
          }
        }, [_vm._v(" Office/Commercial (10 AM - 6 PM delivery) ")]), _c('option', {
          attrs: {
            "value": "Other"
          }
        }, [_vm._v("Other")])])], 1), _c('v-col', {
          staticStyle: {
            "margin-top": "4px",
            "font-size": "19px"
          },
          attrs: {
            "cols": "12",
            "sm": "6",
            "md": "6"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "5"
          }
        }, [_c('b-card-text', {
          staticClass: "price-details"
        }, [_vm._v("Default Address")])], 1), _c('b-col', {
          attrs: {
            "md": "7"
          }
        }, [_c('b-form-radio-group', {
          attrs: {
            "id": "radio-group-2",
            "name": "radio-sub-component"
          },
          model: {
            value: _vm.defaultAddressKey,
            callback: function callback($$v) {
              _vm.defaultAddressKey = $$v;
            },
            expression: "defaultAddressKey"
          }
        }, [_c('b-form-radio', {
          attrs: {
            "size": "lg",
            "value": true
          }
        }, [_vm._v("Yes")]), _c('b-form-radio', {
          attrs: {
            "size": "lg",
            "value": false
          }
        }, [_vm._v("No")])], 1)], 1)], 1)], 1), _c('b-col', {
          staticClass: "text-center"
        }, [!_vm.isLoading ? [_c('button', {
          staticClass: "btn btn-outline-primary btn-lg mr-3",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              _vm.$bvModal.hide('bv-add-address');

              _vm.reset();
            }
          }
        }, [_c('span', {
          staticClass: "button-text"
        }), _vm._v(" Cancel ")]), _c('button', {
          staticClass: "btn btn-primary btn-lg",
          attrs: {
            "type": "button",
            "disabled": invalid
          },
          on: {
            "click": _vm.onSubmitAddress
          }
        }, [_c('span', {
          staticClass: "button-text"
        }), _vm._v(" Save ")])] : _vm._e(), _vm.isLoading ? _vm._l(3, function (i) {
          return _c('b-spinner', {
            key: i,
            staticClass: "mr-1",
            attrs: {
              "small": "",
              "label": "Small Spinner",
              "type": "grow"
            }
          });
        }) : _vm._e()], 2)], 1)];
      }
    }])
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }